<template>
  <v-stepper v-model="step" vertical class="elevation-0">
    <v-stepper-step :complete="step > 1" step="1">
      Legal Name
    </v-stepper-step>

    <v-stepper-content step="1">
      <div class="py-8">
          <v-text-field
            autofocus
            v-model="title"
            label="Legal Name"
            outlined
            clearable
            hint="This name should match the image of your government/license/id provided in the next step."
          ></v-text-field>
      </div>
      <v-btn color="primary" @click.prevent="create" :loading="loading" :disabled="!title">Continue</v-btn>
      <!--<v-btn text>Cancel</v-btn>-->
    </v-stepper-content>

    <v-stepper-step :complete="step > 2" step="2">Upload a photo holding your id</v-stepper-step>

    <v-stepper-content step="2">
      <v-row class="py-8">
        <v-col sm="3">
          <v-img src="https://bgia.sfo2.digitaloceanspaces.com/web/app/id-example.jpg"></v-img>
        </v-col>
        <v-col sm="9">
          <v-file-input
            class="mt-sm-12"
            outlined
            label="Government ID Photo"
            prepend-icon="mdi-camera"
            hint="Upload a photo of yourself holding your ID where the name/information can be read."
            :persistent-hint="true"
            v-model="id_file"
          ></v-file-input>
        </v-col>

      </v-row>

      <v-btn color="primary" @click="attachIdFile" :disabled="!id_file">Continue</v-btn>
      <!--<v-btn text>Cancel</v-btn>-->
    </v-stepper-content>

    <v-stepper-step :complete="step > 3" step="3">Upload clear photos of your face</v-stepper-step>

    <v-stepper-content step="3">
      <v-row class="py-8">

        <v-col sm="3">
          <v-img src="https://bgia.sfo2.digitaloceanspaces.com/web/app/face-example.jpg"></v-img>
        </v-col>
        <v-col sm="9">
          <v-file-input
            outlined
            class="mt-sm-12"
            label="Clear Face Photo"
            prepend-icon="mdi-camera"
            hint="Upload a clear photo of your face, unobstructed and with good light like by a window."
            :persistent-hint="true"
            v-model="face_file"
          ></v-file-input>
        </v-col>
      </v-row>

      <v-btn color="primary" @click.prevent="attachFaceFile" :disabled="!face_file">Continue</v-btn>
      <!--<v-btn text>Cancel</v-btn>-->
    </v-stepper-content>

    <v-stepper-step step="4">
      Review
    </v-stepper-step>
    <v-stepper-content step="4">
      <div class="py-8">
        <h4 class="headline text-uppercase">We're reviewing your submission.</h4>

        <p>
          For privacy reasons, we need to verify that our face search technology is limited to those who have a right to
          find themselves online.  Please be patient while our team confirms your identity.
        </p>
      </div>

      <v-btn color="primary" @click="finish">Done</v-btn>
      <!--<v-btn text>Cancel</v-btn>-->
    </v-stepper-content>
  </v-stepper>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex';
  import Vue from "vue";
  export default {
    data: () => ({
      loading: false,
      //step: 1,
      id_file: null,
      face_file: null,

      /* Model */
      id: null,
      title: null,
      faces: [],
      ids: []
    }),
    props: {
      requestedId: {
        type: Number,
        default: null
      }
    },
    mounted() {
      if (this.requestedId)
        this.load(this.requestedId);

      this.title = this.name;
    },
    computed: {
      ...mapGetters({
        name: 'auth/name',
      }),
      step() {
        if (!this.id) {
          return 1;
        } else if (this.ids.length === 0) {
          return 2;
        } else if (this.faces.length === 0) {
          return 3;
        } else {
          return 4;
        }
      }
    },
    methods: {
      ...mapActions({
        addIdentification: 'identifications/addItem',
        loadIdentifications: 'identifications/loadItems'
      }),
      load(id) {
        this.$http.get(`identifications/${id}`).then(res => {
          Object.assign(this, res.data);
        }).catch(res => {
          // ignore
        })
      },
      create() {
        if (this.loading)
          return;

        this.loading = true;
        this.$http.post(`identifications`, {
          title: this.title
        }).then(res => {
          Object.assign(this, res.data);
        }).catch(res => {
          console.log(res);
        }).finally(res => {
          this.loading = false;
        });
      },
      attachIdFile() {
        this.attachFile(this.id_file, 'gov_id')
          .then(res => {
            this.ids.push(res.data);
          })
          .catch(error => {
            console.error(error);
            //console.error(error.response.data);
          });

        // this.ids.push(new ids attachment)
      },
      attachFaceFile() {
        this.attachFile(this.face_file, 'face')
          .then(res => {
            this.faces.push(res.data);
          })
          .catch(error => {
            console.error(error);
            //console.error(error.response.data);
          });

        // this.ids.push(new faces attachment)
      },
      async attachFile(file, role) {
        if (!file)
          return;

        this.loading = true;

        let formData = new FormData();

        let filename;
        if (file.type == 'image/jpeg') {
          filename = "image.jpg";
        } else {
          filename = "image.png";
        }

        formData.set('role', role);

        formData.append('image', file, {
          name: filename,
          type: file.type
        });

        return this.$http.post(`identifications/${this.id}/attachments`, formData);
      },
      async finish() {
        await this.loadIdentifications();
      }
    }
  }
</script>

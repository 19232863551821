<template>
  <div class="signup pt-12">

    <v-container>
      <v-row>
        <v-col offset-sm="3" sm="6">

          <v-card flat>

            <v-card-text>

              <h1 class="headline mb-8 text-uppercase">Contact Beegia</h1>

              <p>Thanks in advance for reaching out to our team!  We'll try to get back to you as soon as possible.</p>


              <div v-if="!sent">
                <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
                >

                  <v-text-field
                    autofocus
                    outlined
                    v-model="name"
                    name="name"
                    :rules="nameRules"
                    label="Name"
                    required
                  ></v-text-field>

                  <v-text-field
                    outlined
                    v-model="subject"
                    name="subject"
                    :rules="subjectRules"
                    label="Subject"
                    required
                  ></v-text-field>

                  <v-text-field
                    outlined
                    v-model="email"
                    name="email"
                    :rules="emailRules"
                    label="E-mail"
                    required
                  ></v-text-field>

                  <v-textarea
                    no-resize
                    outlined
                    counter
                    label="Message"
                    :rules="messageRules"
                    v-model="message"
                  ></v-textarea>

                  <div class="mb-8 mt-0">
                    <recaptcha continer="contact"></recaptcha>
                  </div>

                  <v-btn
                    :disabled="!valid || !completed"
                    :loading="loading"
                    block
                    x-large
                    color="primary"
                    class="mt-4"
                    @click="validate"
                  >
                    SEND MESSAGE
                  </v-btn>

                  <div class="mt-4 text-center" v-if="false">
                    By contacting us, you agree to Beegia's
                    <a href="/policy/terms" target="_blank" rel="noopener noreferrer" class="">Terms of Use</a>,
                    <a href="/policy/privacy"  target="_blank" rel="noopener noreferrer" class="">Privacy Policy</a> and
                    <a href="/policy/cookies"  target="_blank" rel="noopener noreferrer" class="">Cookie Policy</a>.
                  </div>

                </v-form>
              </div>
              <div v-else>
                <alert type="info">Your message has been received and we will respond ASAP.</alert>
              </div>


            </v-card-text>

          </v-card>

        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import Recaptcha from "../components/Recaptcha";
  import {mapGetters} from "vuex";
  export default {
    data: () => ({
      sent: false,
      valid: true,
      loading: false,
      name: '',
      nameRules: [
        v => !!v || 'Name is required',
        v => (v && v.length >= 2 && v.length <= 30) || 'Name must be between 3 and 30 characters',
      ],
      subject: '',
      subjectRules: [
        v => !!v || 'Subject is required',
        v => (v && v.length >= 2 && v.length <= 100) || 'Name must be between 3 and 30 characters',
      ],
      email: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      message: '',
      messageRules: [
        v => !!v || 'Message is required',
        v => (v && v.length >= 10 && v.length <= 500) || 'Message must be between 20 and 500 characters',
      ],
      revealPassword: false,
      select: null,
      checkbox: false,
    }),
    components: {Recaptcha},
    computed: {
      ...mapGetters({
        id: 'auth/id',
        recaptchas: 'recaptcha/responses',
        allRecaptchas: 'recaptcha/allResponses'
      }),
      completed() {
        if (!this.email || !this.name || !this.message)
          return false;

        if (this.allRecaptchas.length == 0)
          return false;

        if (!this.recaptchas.for('contact'))
          return false;

        return true;
      }
    },
    methods: {
      validate () {
        this.loading = true;
        this.$refs.form.validate();
        this.$http.post('inquiries', {
          email: this.email,
          name: this.name,
          subject: this.subject,
          message: this.message,
          recaptcha: this.recaptchas.for('contact')
        }).then(res => {
          this.sent = true;
        }).catch(err => {
          console.error(err.response.data);
        }).finally(res => {
          this.loading = false;
        })
      },
      reset () {
        this.$refs.form.reset()
      },
      resetValidation () {
        this.$refs.form.resetValidation()
      },
    },
  }
</script>

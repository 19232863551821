import Vue from 'vue'
import Vuex from 'vuex'

import app from './app';
import auth from './auth';
import team from './team';
import recaptcha from './recaptcha';
import query from './query';
import query_results from './query_results';
import identifications from './identifications';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    app,
    auth,
    team,
    recaptcha,
    query,
    query_results,
    identifications
  }
})

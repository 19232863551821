import Vue from 'vue';
import {errorableState, errorableGetters, errorableMutations, errorableActions} from "./mixins/errorable";

const state = {
  ...errorableState(),
  loading: false,
  id: null,
  username: null,
  name: null,
  email: null,
  email_verified_at: null,
  password: null,
  created_at: null
}

const getters = {
  ...errorableGetters(),
  loading: state => {
    return state.loading;
  },
  id: state => {
    return state.id;
  },
  name: state => {
    return state.name;
  },
  email: state => {
    return state.email;
  },
  email_verified_at: state => {
    return state.email_verified_at;
  }
}

const actions = {
  ...errorableActions(),
  async loadMe( {commit, dispatch} ) {
    return Vue.http.get("me", {
      email: state.email,
      password: state.password
    })
      .then(res => {
        commit('UPDATE', {
          id: res.data.id,
          name: res.data.name,
          email: res.data.email,
          email_verified_at: res.data.email_verified_at
        });
      })
      .catch(error => {
        dispatch('setApiErrors', error);
      });
  },
  async signUp( {commit, state, dispatch, rootState, rootGetters} ) {
    commit('UPDATE', {loading: true});
    Vue.http.post("/registrations", {
      name: state.name,
      email: state.email,
      password: state.password,
      recaptcha: rootGetters['recaptcha/responses'].for('signup')
    }).then(res => {
      commit('UPDATE', res.data);
    }).catch(error => {
      dispatch('setApiErrors', error);
    }).finally(res => {
      commit('UPDATE', {loading: false});
    });
  },
  async signIn( {commit, state, dispatch} ) {
    commit('UPDATE', {loading: true});
    return Vue.http.post("/sessions", {
      email: state.email,
      password: state.password
    }).then(res => {
      commit('UPDATE', res.data);
    }).catch(error => {
      dispatch('setApiErrors', error);
      return Promise.reject('Failed');
    }).finally(res => {
      commit('UPDATE', {loading: false});
    });
  },
  async resend( {commit, state, dispatch} ) {
    return Vue.http.post("/me/email_verifications").then(res => {
      return res.data;
    }).catch(error => {

    });
  },
  async logout( {commit, state, dispatch} ) {
    return Vue.http.delete("/sessions/me")
      .then(res => {
        commit('UPDATE', {
          id: null,
          name: null,
          email: null,
          email_verified_at: null
        });
      });
  },
  async hello( {commit, state, dispatch} ) {
    return Vue.http.get('hello');
  }
}

const mutations = {
  ...errorableMutations(),
  UPDATE: (state, data) => {
    Object.keys(data).forEach(k => {
      state[k] = data[k];
    });
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

<template>
  <div class="manager">
    <v-container>
      <h3 class="headline mb-6">MANAGER</h3>

      <v-alert
        type="info"
        class="mb-4"
        v-if="id && !email_verified_at"
      >
        Please confirm your email address by checking your inbox and spam folder for {{email}} as soon as possible.

        <v-divider
          class="my-4"
          dark
          style="opacity: 0.92"
        ></v-divider>

        <v-btn
          dark
          outlined
          class="mr-3"
          @click.prevent="loadMe"
        >
          Done
        </v-btn>

        <v-btn
          dark
          outlined
          @click.prevent="resend"
        >
          Re-send
        </v-btn>
      </v-alert>

      <router-view></router-view>
    </v-container>
  </div>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex';
  export default {
    data: () => ({
      loading: true,

    }),
    mounted() {
      if (!this.id)
        this.$router.replace('login');
    },
    components: {

    },
    computed: {
      ...mapGetters({
        id: 'auth/id',
        email: 'auth/email',
        email_verified_at: 'auth/email_verified_at'
      })
    },
    methods: {
      ...mapActions({
        loadMe: 'auth/loadMe',
        resend: 'auth/resend'
      })
    }
  }

</script>


<style>

</style>

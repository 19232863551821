<template>
  <v-app>
    <app-drawer-main></app-drawer-main>
    <app-bar-main></app-bar-main>

    <v-spacer class="mt-12"></v-spacer>

    <v-content>
      <div v-if="loaded">
        <v-container>
          <div v-if="appErrors.length > 0" class="mb-4">
            <alert v-for="error in appErrors" :key="error" type="error" class="mb-4">{{error}}</alert>
          </div>
        </v-container>

        <router-view></router-view>
      </div>
    </v-content>

    <v-spacer class="mt-12"></v-spacer>

    <footer-main class="mt-12"></footer-main>
  </v-app>
</template>

<script>
import AppBarMain from "./components/AppBarMain";
import AppDrawerMain from "./components/AppDrawerMain";
import FooterMain from "./components/FooterMain";

import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'App',
  data: () => ({
    loaded: false
  }),
  components: {
    FooterMain,
    AppDrawerMain,
    AppBarMain
  },
  async mounted() {
    console.log('window.recaptchaCallback');
    window.recaptchaCallback = () => {
      setTimeout(() => {
        this.recaptchaSetReady();
      }, 500)
    };

    // Intro, get xsrf cookie.
    await this.$http.get('hello');
    setInterval(async () => {
      await this.$http.get('hello');
    }, 60000 * 10)

    await this.loadMe();
    this.loaded = true;
  },
  methods: {
    ...mapActions({
      recaptchaSetReady: 'recaptcha/setReady',
      loadMe: 'auth/loadMe'
    })
  },
  computed: {
    ...mapGetters({
      id: 'auth/id',
      email: 'auth/email',
      email_verified_at: 'auth/email_verified_at',
      appErrors: 'app/errors'
    })
  }
};
</script>
<style>

</style>

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';

import Loading from "./components/Loading"
Vue.component('Loading', Loading);

import Alert from "./components/Alert"
Vue.component('Alert', Alert);

Vue.config.productionTip = false


import Axios from 'axios';

Axios.defaults.baseURL = '/api';


Axios.interceptors.request.use(
  config => {
    //console.log(config);
    //config.headers['X-XSRF-TOKEN'] = getCookie('XSRF-TOKEN');
    return config;
  },
  error => Promise.reject(error)
);


Vue.prototype.$http = Axios;
Vue.http = Axios;


new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

function getCookie (name) {
  var value = "; " + document.cookie;
  var parts = value.split("; " + name + "=");
  if (parts.length == 2) return parts.pop().split(";").shift();
}


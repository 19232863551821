
const state = {
  errors: [],
  drawer: false,
  modalComponent: null,
  modalTitle: null,
  modalButtons: [],
  modalLocked: false,
}

const getters = {
  drawer: state => {
    return state.drawer;
  },
  modalComponent: state => {
    return state.modalComponent;
  },
  modalTitle: state => {
    return state.modalTitle;
  },
  modalButtons: state => {
    return state.modalButtons;
  },
  modalLocked: state => {
    return state.modalLocked;
  },
  mainModalButtons: state => {
    return state.modalButtons.filter(e => {
      return !e.position || e.position !== 'left';
    });
  },
  leftModalButtons: state => {
    return state.modalButtons.filter(e => {
      return e.position && e.position == 'left';
    });
  },
  errors: state => {
    return state.errors;
  }
}

const actions = {
  update({ commit, state, dispatch }, data) {
    commit('UPDATE', data);
  },
  setModal({ commit, state, dispatch }, payload) {
    //console.log('store.app.setModal', payload);
    commit('SET_MODAL', payload);
  },
  setModalComponent({ commit, state, dispatch }, component) {
    //console.log('store.app.setModalComponent', component);
    //console.trace("setModalComponent");
    commit('SET_MODAL_COMPONENT', component);
  },
  setModalTitle({ commit, state, dispatch }, title) {
    commit('SET_MODAL_TITLE', title);
  },
  setModalButtons({ commit, state, dispatch }, buttons) {
    commit('SET_MODAL_BUTTONS', buttons);
  },
  mergeModalButton({ commit, state, dispatch }, button) {
    commit('MERGE_MODAL_BUTTON', button);
  },
}

const mutations = {
  SET_MODAL:  (state, payload) => {
    if (!payload.modalLocked)
      payload.modalLocked = false;

    Object.assign(state, payload);
  },
  SET_MODAL_COMPONENT: (state, component) => {
    state.modalComponent = component;
  },
  SET_MODAL_TITLE: (state, title) => {
    state.modalTitle = title;
  },
  SET_MODAL_BUTTONS: (state, buttons) => {
    state.modalButtons = buttons;
  },
  MERGE_MODAL_BUTTON:  (state, button) => {
    if (!Array.isArray(state.modalButtons) || !button.name)
      return;

    let idx = state.modalButtons.findIndex(e => e.name === button.name);
    if (idx < 0)
      return;

    state.modalButtons[idx] = Object.assign(state.modalButtons[idx], button);
  },
  UPDATE: (state, data) => {
    Object.keys(data).forEach(k => {
      state[k] = data[k];
    });

    //ls.persist(localStorageKey, data, lsKeyBase);
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

<template>
  <div class="app-bar-main">
    <v-app-bar
      color="white"
      elevate-on-scroll
      flat
      fixed
      dense
    >
      <v-app-bar-nav-icon @click.prevent="appUpdate({drawer: !drawer})"></v-app-bar-nav-icon>

      <v-toolbar-title>
        <img src="https://bgia.sfo2.digitaloceanspaces.com/web/beegia-bee-mark.png" style="height: 34px;width: auto;position: relative;top: 3px;">
      </v-toolbar-title>

      <!--
      <v-toolbar-title>Page title</v-toolbar-title>
      -->


      <v-spacer></v-spacer>

      <v-btn rounded dark text small class="mr-2" to="login">
        Log In
      </v-btn>

      <v-btn rounded dark depressed outlined small :to="{ path: 'signup', query: { intent: 'create' }}">
        Create
      </v-btn>

      <v-menu
        left
        bottom
      >
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-for="item in listItems"
            :key="item.title"
            :link="true"
            :to="(item.path) ? item.path : null"
            @click.prevent="(item.method) ? item.method() : () => {}"
          >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
  </div>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex';

  export default {
    components: {

    },
    data: () => ({
      guestListItems: [
        {title: 'Sign In', path: 'login'}
      ],
      userListItems: [
        {title: 'Sign Out', method: 'logout'}
      ],
    }),
    computed: {
      ...mapGetters({
        drawer: 'app/drawer',
        authId: 'auth/id'
      }),
      listItems() {
        let list;
        if (this.authId) {
          list = this.userListItems;
        } else {
          list = this.guestListItems;
        }

        return list.map(e => {
          if (e.method)
            e.method = this[e.method];

          return e;
        });
      }
    },
    methods: {
      ...mapActions({
        appUpdate: 'app/update',
        logout: 'auth/logout'
      })
    }
  }
</script>


import {errorableActions, errorableGetters, errorableMutations, errorableState} from "./mixins/errorable";
import Vue from "vue";

const endpoint = 'identifications';

const state = {
  ...errorableState(),
  loading: false,
  items: [],
  total: 0,
  page: 1,
}

const getters = {
  ...errorableGetters(),
  items: state => {
    return state.items;
  },
  total: state => {
    return state.total;
  }
}

const actions = {
  ...errorableActions(),
  async loadItems( {commit, dispatch}, params ) {
    return Vue.http.get(`/${endpoint}`)
      .then(res => {
        commit('SET_ITEMS', res.data);
      })
  },
  async addItem( {commit, dispatch }, item) {
    return commit('ADD_ITEM', item);
  }
}

const mutations = {
  ...errorableMutations(),
  UPDATE: (state, data) => {
    Object.keys(data).forEach(k => {
      state[k] = data[k];
    });
  },
  ADD_ITEM: (state, data) => {
    let existingIdx = state.items.findIndex(e => {
      return e.id == data.id;
    });

    if (existingIdx > -1)
      return;

    state.items.unshift(data);
  },
  SET_ITEMS: (state, data) => {
    // data.total, data.current_page, data.per_page, data.from, data.to, data.last_page
    state.items = data.data;
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

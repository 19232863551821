<template>
  <div class="home">
    <!--<HelloWorld msg="Welcome to Your Vue.js App"/>-->

    <section class="mb-8 py-8">
      <v-responsive style="max-width:1390px;" class="mx-auto">
        <v-container>
          <v-row>
            <v-col cols="12" sm="6">
              <v-img
                src="https://bgia.sfo2.digitaloceanspaces.com/web/beegia-vertical-logo.png"
                class="my-3"
                contain
                height="220"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <h1 class="headline text-uppercase mb-7">MEET THE QUEEN BEE.</h1>

              <p class="font-weight-thin mb-8">
                Beegia commands her workers to fly around the web looking for
                traces of colorful choices you may have left behind so you
                have the opportunity to erase them using legally enforceable
                DMCA notices crafted by really awesome lawyers.
              </p>

              <v-btn rounded dark color="teal" :to="{ path: 'contact', query: { intent: 'inquire' }}">
                INQUIRE
              </v-btn>
            </v-col>

          </v-row>
        </v-container>
      </v-responsive>
    </section>

    <section class="py-6">
      <v-responsive style="max-width:1390px;" class="mx-auto">
        <v-container fluid>
          <v-row>
            <v-col
              class="text-center"
              cols="12"
              sm="6"
              md="3"
              v-for="feature in features"
              :key="feature.title"
            >
              <v-icon class="mb-2" x-large :color="feature.color">{{feature.icon}}</v-icon>

              <h3 class="mb-4 text-uppercase headline">{{feature.title}}</h3>

              <p class="font-weight-thin">{{feature.text}}</p>
            </v-col>
          </v-row>
        </v-container>
      </v-responsive>
    </section>

  </div>
</template>

<script>

export default {
  name: 'Home',
  data() {
    return {
      features: [
        {title:'Submit your Face', color:'purple', icon: 'mdi-face-recognition',
          text: "Upload a clear and unobstructed photo of your face, in private, so our bees know who to look for. Don't worry, your face is stored securely in our cloud vault."},
        {title:'The Bees Search', color:'teal darken-2', icon: 'mdi-movie-search',
          text: "We'll search our current database while the bees monitor the colorful world of video content looking for your beautiful mug. They're always searching, always."},
        {title:'Receive a Report', color:'pink darken-2', icon: 'mdi-message-video',
          text: "When our bees find someone they think looks like you, we'll notify you right away via email and provide you with an encrypted link to view what they've found."},
        {title:'Review and Submit', color:'deep-orange darken-2', icon: 'mdi-email-check',
          text: "We don't do anything without your approval and consent.  When you see something that you're sure is you send a legally binding DMCA take-down notice instantly."},
      ]
    }
  },
  components: {


  }
}
</script>

<style lang="scss">
  .home {
    h1.headline {
      font-size: 3.5rem !important;
      line-height: 4rem;
    }
  }
</style>

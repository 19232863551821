<template>
  <div class="manager-home">
    <div v-if="ready">

      <v-row v-if="!blankSlate">
        <v-col>
          <!--<v-btn @click.prevent="add">Track New Face</v-btn>-->
        </v-col>
      </v-row>

      <identification-creator  v-if="blankSlate" :requested-id="blankSlateId"></identification-creator>

      <div v-if="!blankSlate">
        <div v-for="identification in identifications" :key="identification.id" class="mb-1">
            <v-row>
              <v-col cols="12" sm="2">
                <v-img :src="identification.faces[0].uri" :aspect-ratio="1.7778">
                  <v-row align="end" class="fill-height">
                    <v-col>
                      <v-chip
                        label
                        small
                        class="ml-3"
                      >
                        Face
                      </v-chip>
                    </v-col>
                  </v-row>
                </v-img>
              </v-col>
              <v-col cols="12" sm="2">
                <v-img :src="identification.ids[0].uri" :aspect-ratio="1.7778">
                  <v-row align="end" class="fill-height">
                    <v-col>
                      <v-chip
                        label
                        small
                        class="ml-3"
                      >
                        Verification
                      </v-chip>
                    </v-col>
                  </v-row>
                </v-img>
              </v-col>
              <v-col cols="12" sm="8">
                <v-container fill-height>
                  <v-row align="center">
                    <v-col sm="4" class="align-sm-center">
                      <v-icon large color="gray">mdi-message-text</v-icon> Notes (0)
                    </v-col>
                    <v-col sm="4" class="align-sm-center">
                      <v-chip
                        class="ma-2"
                        color="indigo"
                        text-color="white"
                      >
                        <v-avatar left>
                          <v-icon>mdi-account-circle</v-icon>
                        </v-avatar>
                        Under Review
                      </v-chip>
                    </v-col>
                    <v-col sm="4" align="right">
                      <v-menu bottom left>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            icon
                            v-on="on"
                          >
                            <v-icon>mdi-dots-vertical</v-icon>
                          </v-btn>
                        </template>

                        <v-list>
                          <v-list-item
                            v-for="(item) in [{title:'Delete', action: 'dance'}]"
                            :key="item.action"
                            @click="() => {}"
                          >
                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
        </div>
      </div>
    </div>
    <!--<img src="https://img.over-blog-kiwi.com/4/08/31/79/20200206/ob_5f6835_4.png">-->
  </div>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex';
  import IdentificationCreator from "../../components/IdentificationCreator";
  export default {
    name: 'ManagerHome',
    components: {IdentificationCreator},
    data() {
      return {
        ready: false
      }
    },
    async mounted() {
      await this.loadItems();
      this.ready = true;
    },
    computed: {
      ...mapGetters({
        identifications: 'identifications/items'
      }),
      blankSlate() {
        if (this.identifications.length === 0)
          return true;

        // awaiting first face completion
        if (this.identifications[0].faces.length === 0 || this.identifications[0].ids === 0)
          return true;

        return false;
      },
      blankSlateId() {
        if (!this.blankSlate)
          return null;

        if (this.identifications[0])
          return this.identifications[0].id;

        return null;
      }
    },
    methods: {
      ...mapActions({
        loadItems: 'identifications/loadItems'
      }),
      add() {

      }
    }
  }
</script>

<template>
  <div class="manager-faces">
    <h1>Faces</h1>
  </div>
</template>

<script>
  export default {
    name: 'ManagerFaces',
    data() {
      return {

      }
    },
  }
</script>

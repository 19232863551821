import mockResults from "../mocks/query-results";

const state = {
  query: null,
  results: null,
}

const getters = {
  results: state => {
    return state.results;
  },
  query: state => {
    return state.query;
  },
}

const actions = {
  async clearResults( {commit} ) {
    commit('UPDATE', {results: null});
    commit('UPDATE', {query: null});
  },
  async setResult( {commit, state, dispatch}, query) {
    // some slight normalization
    commit('UPDATE', {results: query.results})
    delete query.results;
    commit('UPDATE', {query: query})
  }
}

const mutations = {
  UPDATE: (state, data) => {
    Object.keys(data).forEach(k => {
      state[k] = data[k];
    });
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

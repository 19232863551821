<template>
  <div class="login">

    <v-container>
      <v-row>
        <v-col offset-sm="3" sm="6">

          <h1 class="headline mb-2 text-uppercase">Sign In</h1>

          <p class="mb-12">
            If you haven't yet create a Beegia account please <router-link to="/signup" rel="noopener noreferrer" class="">Sign Up</router-link> here
            to get started.
          </p>

          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >

            <v-text-field
              autofocus
              outlined
              v-model="email"
              name="email"
              :rules="emailRules"
              label="E-mail"
              :error="errors.has('email')"
              :error-messages="errors.first('email')"
              required
            ></v-text-field>

            <v-text-field
              v-model="password"
              outlined
              type="password"
              name="password"
              label="Password"
              hint="Between 8-20 characters with a special character"
            ></v-text-field>

            <v-checkbox
              v-model="remember_me"
              label="Keep me signed in"
              required
            ></v-checkbox>

            <v-btn
              :loading="loading"
              block
              large
              color="primary"
              class="mt-4"
              @click="validate"
            >
              SIGN IN
            </v-btn>

            <div class="mt-4 text-center">
              By signing in, you agree to Beegia's
              <router-link to="/policy/terms" target="_blank" rel="noopener noreferrer" class="">Terms of Use</router-link>,
              <router-link to="/policy/privacy"  target="_blank" rel="noopener noreferrer" class="">Privacy Policy</router-link> and
              <router-link to="/policy/cookies"  target="_blank" rel="noopener noreferrer" class="">Cookie Policy</router-link>.
            </div>

          </v-form>

          <div class="text-center my-8">
            Forgot your password? <router-link to="reset">Reset Password</router-link>.
          </div>


        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex';

  export default {
    data: () => ({
      valid: true,
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      passwordRules: [
        v => !!v || 'Password is required'
      ],
      remember_me: true,
    }),
    computed: {
      ...mapGetters({
        loading: 'auth/loading',
        errors: 'auth/errors'
      }),
      email: {
        get() {
          return this.$store.state.auth.email;
        },
        set(val) {
          this.$store.commit('auth/UPDATE', {email: val});
        }
      },
      password: {
        get() {
          return this.$store.state.auth.password;
        },
        set(val) {
          this.$store.commit('auth/UPDATE', {password: val});
        }
      }
    },
    methods: {
      ...mapActions({
        signIn: 'auth/signIn'
      }),
      async validate () {
        this.$refs.form.validate();
        this.signIn().then(res => {
          this.$router.replace({path:'manager'});
        }).catch(err => {
          // nope
        });

      },
      reset () {
        this.$refs.form.reset()
      },
      resetValidation () {
        this.$refs.form.resetValidation()
      },
    },
  }
</script>

import Vue from 'vue';
import {errorableState, errorableGetters, errorableMutations, errorableActions} from "./mixins/errorable";

const state = {
  ...errorableState(),
  loading: false,
  image: null,
  id: null,
  results: []
}

const getters = {
  ...errorableGetters(),
  loading: state => {
    return state.loading;
  },
  image: state => {
    return state.image;
  },
  results: state => {
    return state.results;
  },
  id: state => {
    return state.id;
  }
}

const actions = {
  ...errorableActions([]),
  async create( {commit, state, dispatch}, {image, asGuest}) {
    commit('CLEAR_ERRORS');
    commit('UPDATE', {loading: true});

    let formData = new FormData();
    let path = (asGuest) ? "guest/queries" : "queries";

    let filename;
    if (image.type == 'image/jpeg') {
      filename = "image.jpg";
    } else {
      filename = "image.png";
    }
    console.log(filename);

    formData.append('face', image, {
      name: filename,
      type: image.type
    });

    return Vue.http.post(path, formData).then(res => {
      commit('UPDATE', res.data);
      dispatch('query_results/setResult', res.data, {root:true})
    }).catch(res => {
      dispatch('setApiErrors', res);
      console.error(res.response.data);
    }).finally(() => {
      commit('UPDATE', {loading: false});
    })
  }
}

const mutations = {
  ...errorableMutations(),
  UPDATE: (state, data) => {
    Object.keys(data).forEach(k => {
      state[k] = data[k];
    });
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

<template>
  <div class="query-results">
    <v-row v-if="results && results.length > 0">
      <v-col cols="12">
        <v-slider
          v-model="ex3.val"
          :min="40"
          :max="80"
          :label="ex3.label"
          :thumb-color="ex3.color"
          thumb-label="always"
          class="mb5"
        ></v-slider>

        <alert v-if="results && filteredResults.length === 0" type="info">
          No faces above your match threshold.
        </alert>

      </v-col>
    </v-row>
    <v-row
      class="mb-6"
    >

      <v-col
        v-for="result in filteredResults"
        :key="result.id"
        cols="12"
        sm="6"
      >
        <v-card
          class="pa-2"
          outlined
          tile
        >
          <v-sheet height="180" color="grey lighten-4 mb-3">
            <v-img :src="result.face.uri" height="180" contain></v-img>
          </v-sheet>

          <div class="">
            <div class="mb-4">
              <img class="d-inline-block" style="position: relative;top: 4px;margin-right: 10px; height: 16px;" :src="`https://bgia.sfo2.digitaloceanspaces.com/web/logos/${result.face.url.publisher}.png`" />

              <v-chip
                small
              >
                {{result.score}}
              </v-chip>
            </div>

            <div class="ellipsis"><a :href="result.face.url.url" target="_blank" rel="noreferrer">{{result.face.url.title}}</a></div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex';
  import mockResults from "../mocks/query-results";
  export default {
    name: 'QueryResults',
    data: () => ({
      ex3: { label: 'Accuracy', val: 50, color: 'pink' },
    }),
    mounted() {
      // testing
      if (process.env.NODE_ENV != 'production')
        this.setResult(mockResults);
    },
    computed: {
      ...mapGetters({
        results: 'query_results/results',
        query: 'query_results/query'
      }),
      filteredResults() {
        if (!this.results || this.results.length === 0)
          return [];

        let minScore = this.ex3.val / 100;
        return this.results.filter(e => {
            return e.score >= minScore;
        })
      }
    },
    methods: {
      ...mapActions({
        setResult: 'query_results/setResult'
      })
    }
  }
</script>

<style lang="scss">
  .query-results {
    .ellipsis {
      text-overflow: ellipsis;

      /* Required for text-overflow to do anything */
      white-space: nowrap;
      overflow: hidden;
    }
  }
</style>

<template>
  <v-progress-circular
    :size="70"
    :width="7"
    color="purple"
    indeterminate
    class="mx-auto d-block loading"
  ></v-progress-circular>
</template>

<script>
  export default {
    name: 'Loading',

    data: () => ({

    }),
  }
</script>

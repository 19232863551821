<template>
  <div class="guest pt-12">

    <v-container>
      <v-row v-if="guest">
        <v-col offset-sm="3" sm="6">
          <div class="mb-12 text-right"><v-icon>mdi-key-variant</v-icon> Guest: {{guest.name}}</div>

          <div class="mb-8" v-if="!results">
            <query-creator queryable-type="Guest" :queryable-id="guest.id"></query-creator>
          </div>
          <div class="mb-8" v-else>
            <v-btn class="" fab dark small color="pink" @click.prevent="clearResults">
              <v-icon dark>mdi-arrow-left-bold</v-icon>
            </v-btn>
          </div>

          <div class="mb-8">
            <query-results></query-results>
          </div>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col offset-sm="3" sm="6">
          <div v-if="loadingAuthenticated">
            <loading></loading>
          </div>
          <div v-else>
            <alert type="error">Invalid credentials.  Please contact us.</alert>
          </div>

        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import QueryCreator from "../components/QueryCreator";
  import QueryResults from "../components/QueryResults";
  import {mapGetters, mapActions} from 'vuex';
  export default {
    data: () => ({
      valid: true,
      guest: null,
      loadingAuthenticated: true,

    }),
    async mounted() {
      await this.authenticate();
    },
    components: {QueryResults, QueryCreator},
    computed: {
      ...mapGetters({
        results: 'query_results/results',
        query: 'query_results/query'
      })
    },
    methods: {
      ...mapActions({
        clearResults: 'query_results/clearResults'
      }),
      async authenticate() {
        return this.$http.get('guest/me').then(res => {
          this.guest = res.data;
        }).catch(res => {
          console.error('Failed');
        }).finally(res => {
          this.loadingAuthenticated = false;
        })
      },
      validate () {
        this.$refs.form.validate()
      },
      reset () {
        this.$refs.form.reset()
      },
      resetValidation () {
        this.$refs.form.resetValidation()
      },

    },
  }
</script>

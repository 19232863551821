<template>
  <div class="query-creator">
    <div v-if="!queryLoading">
      <v-file-input
        label="Upload Image (with a face)"
        filled
        prepend-icon="mdi-camera"
        @change="onChange"
        accept="image/x-png,image/jpeg"
        :error-messages="queryErrors.first('face')"
        class="mb-6"
      ></v-file-input>

      <alert type="info">
        <b>Browser Paste <span v-if="isTextareaFocus">Enabled</span><span v-else>Disabled</span></b><br>
        Paste an image from your clipboard. <a href="" @click.prevent="pastableFocus" v-if="!isTextareaFocus">Enable</a>

        <v-row>
          <v-col sm="6">
            To copy a face on part of your screen on a <b>mac</b> use shortcut
            <i>shift+control+command+4</i> or on <b>windows</b> click <i>win + shift + s</i>
          </v-col>
          <v-col sm="6">
            To paste just make sure it says "Browser Paste Enabled" and on mac <i>command+v</i>
            or on windows <i>control+v</i>.
          </v-col>
        </v-row>

      </alert>
      <textarea
        autofocus
        ref="pastable"
        style="resize: none; width: 1px; height: 1px"
        @paste="handleTextareaPaste"
        @focus="handleTextareaFocus"
        @blur="handleTextareaBlur"
        v-model="text"
        placeholder=""
      ></textarea>
    </div>
    <div v-else>
      <loading>Detecting Face</loading>
    </div>

  </div>
</template>

<script>
  import {mapActions, mapGetters} from 'vuex';
  export default {
    name: 'QueryCreator',
    data: () => ({
      isTextareaFocus: true,
      text: null
    }),
    props: {
      queryableType: {
        type: String,
        default: 'User', // success, info, warning or error
        validator: function (value) {
          return ['User', 'Guest'].indexOf(value) !== -1
        }
      },
      queryableId: {
        type: Number,
        required: true
      },
    },
    mounted() {
      this.$refs.pastable.focus();
    },
    computed: {
      ...mapGetters({
        queryLoading: 'query/loading',
        queryImage: 'query/image',
        queryId: 'query/id',
        queryErrors: 'query/errors'
      }),
    },
    methods: {
      ...mapActions({
        queryCreate: 'query/create'
      }),
      onChange(file) {
        this.fileUpload(file)
      },
      handleTextareaPaste (event) {
        var image
        if (event.clipboardData && event.clipboardData.items) {
          image = isImage(event.clipboardData.items)
          if (image) {
            event.preventDefault()
            const file = image.getAsFile()
            //file.name = getFilename(event) || 'image-' + Date.now() + '.png'
            return this.fileUpload(file);
          }
        }
      },
      handleTextareaFocus (e) {
        this.isTextareaFocus = true;
      },
      handleTextareaBlur (e) {
        this.isTextareaFocus = false;
      },
      fileUpload(file) {
        this.queryCreate({image: file, asGuest: true});
      },
      pastableFocus() {
        setTimeout(() => {
          this.$refs.pastable.focus();
        }, 50)
      }
    }
  }

  function isImage (items) {
    var i = 0
    var item
    while (i < items.length) {
      item = items[i]
      if (item.type.indexOf('image') !== -1) {
        return item
      }
      i++
    }
    return false
  }

  function getFilename (e) {
    var value
    if (window.clipboardData && window.clipboardData.getData) {
      value = window.clipboardData.getData('Text')
    } else if (e.clipboardData && e.clipboardData.getData) {
      value = e.clipboardData.getData('text/plain')
    }
    value = value.split('\r')
    return value[0]
  }
</script>

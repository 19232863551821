import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Signup from '../views/Signup';
import Login from '../views/Login';
import Contact from '../views/Contact';
import Guest from '../views/Guest';

// Manager
import Manager from '../views/Manager.vue';
import ManagerHome from '../views/Manager/Home.vue';
import ManagerFaces from '../views/Manager/Faces.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
    {
      path: '/contact',
      component: Contact
    },
  {
    path: '/signup',
    component: Signup
  },
  {
    path: '/login',
    component: Login
  },
  {
    path: '/guest',
    component: Guest,
  },
  {
    path: '/manager',
    component: Manager,
    children: [
      {
        path: '',
        component: ManagerHome
      },
      {
        path: 'faces',
        component: ManagerFaces
      }
    ]
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router

<template>

      <v-footer
        padless
      >

        <v-card
          flat
          tile
          class="white--text py-12"
          style="background-color: rgb(46, 46, 53)"
          width="100%"
        >
          <v-card-text class="text-center mb-6" v-if="showSocials">
            <v-btn
              v-for="social in socials"
              :key="social.title"
              class="mx-4 white--text"
              :href="social.url"
              icon
            >
              <v-icon size="24px">{{ social.icon }}</v-icon>
            </v-btn>
          </v-card-text>

          <v-responsive style="max-width:1390px;" class="mx-auto">
            <v-container fluid>
              <v-row>
                <v-col cols="12" sm="4">
                  <div class="title mb-5">Work@Beegia</div>
                  <div class="font-weight-light mb-7">
                    Would you like to join the Beegia team? If you have experience in
                    deep learning and distributed system design please get in touch!
                  </div>

                  <v-carousel
                    v-if="showTeam"
                    cycle
                    dark
                    :next-icon="false"
                    :prev-icon="false"
                    height="200px"
                    hide-delimiters
                    hide-delimiter-background
                    show-arrows-on-hover
                  >
                    <v-carousel-item
                      v-for="member in teamMembers"
                      :key="member.name"
                    >
                      <v-card>
                        <v-list-item three-line>
                          <v-list-item-content>
                            <div class="overline mb-4">BEEGIA TEAM</div>
                            <v-list-item-title class="headline mb-4">{{member.name}}</v-list-item-title>
                            <v-list-item-subtitle>
                              <v-chip
                                class="ma-1"
                                small
                                v-for="role in member.roles"
                                :key="role"
                              >
                                {{role}}
                              </v-chip>
                            </v-list-item-subtitle>
                          </v-list-item-content>

                          <v-list-item-avatar
                            tile
                            size="80"
                            color="grey"
                          >
                            <v-img :src="member.thumb"></v-img>
                          </v-list-item-avatar>
                        </v-list-item>
                      </v-card>
                    </v-carousel-item>
                  </v-carousel>

                </v-col>
                <v-col cols="12" sm="4">
                  <div class="title mb-5">What is Beegia?</div>
                  <div class="font-weight-light mb-7">
                    It's pronounced Bee-GIA.  We're a self-funded deep-tech startup run by folks who believe in the ethical
                    use of deep learning.
                  </div>
                </v-col>
                <v-col cols="12" sm="4">
                  <div class="title mb-5">Get in Touch</div>
                  <div class="font-weight-light mb-7">
                    If you'd like to participate in our private beta please hit us up and let us know
                    your use case.
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-responsive>

          <v-divider></v-divider>

          <v-card-text class="white--text text-center">
            Copyright &copy; {{ new Date().getFullYear() }} — <strong>Beegia</strong>. All rights reserved.
          </v-card-text>
        </v-card>
      </v-footer>


</template>

<script>
  import {mapGetters} from 'vuex';

  export default {
    data: () => ({
      showSocials: false,
      socials: [
        {icon: 'mdi-facebook', title: 'Facebook', url: "https://facebook.com/beegiafaces"},
        {icon: 'mdi-twitter', title: 'Twitter', url: "https://twitter.com/beegiafaces"},
        {icon: 'mdi-linkedin', title: 'LinkedIn', url: "https://linkedin.com/beegiafaces"},
        {icon: 'mdi-instagram', title: 'Instagram', url: "https://instagram.com/beegiafaces"},
      ],
      showTeam: false
    }),
    computed: {
      ...mapGetters({
        teamMembers: 'team/members'
      })
    }
  }
</script>
